<template>
  <nav
    class="navbar navbar-expand-lg fixed-top navbar-light bg-white"
    aria-label="Main navigation"
  >
    <div class="container-fluid flex-column">
      <div
        class="d-inline-flex flex-row w-100 justify-content-between align-items-center"
      > <div class="d-none d-lg-block" style="visibility: hidden;">
        <div
          class="d-flex flex-column align-items-center"
          style="color: #939597; width: 150px; margin-right: 10px"
        >
          <div>
            <span style="font-size: 16px"
              ><strong>STAY CONNECTED</strong></span
            >
          </div>
          <div>
            <a href="#telegram" data-bs-toggle="modal"><i class="fab fa-telegram fa-fw social-icons"></i></a>
            <a href="https://wa.me/6592714988" target="_blank"><i class="fab fa-whatsapp-square fa-fw social-icons"></i></a>
            <a href="https://facebook.com/jhmasterpan" target="_blank"><i class="fab fa-facebook fa-fw social-icons"></i></a>
            <a href="tel:+6592714988"><i class="fas fa-phone-square fa-fw social-icons"></i></a>
          </div>
        </div>
      </div>
      <button
          class="navbar-toggler p-0 border-0"
          type="button"
          aria-label="Toggle navigation"
          style="visibility: hidden;"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <a class="navbar-brand mx-auto" href="https://jhmetaphysics.com"
          ><img src="../assets/logo.png" class="img-fluid nav-logo"
        /></a>
        <button
          class="navbar-toggler p-0 border-0"
          type="button"
          data-bs-toggle="offcanvas"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="d-none d-lg-block">
          <div
            class="d-flex flex-column align-items-center"
            style="color: #939597; width: 150px; margin-right: 10px"
          >
            <div>
              <span style="font-size: 16px"
                ><strong>STAY CONNECTED</strong></span
              >
            </div>
            <div>
              <a href="#telegram" data-bs-toggle="modal"><i class="fab fa-telegram fa-fw social-icons"></i></a>
              <a href="https://wa.me/6592714988" target="_blank"><i class="fab fa-whatsapp-square fa-fw social-icons"></i></a>
              <a href="https://facebook.com/jhmasterpan" target="_blank"><i class="fab fa-facebook fa-fw social-icons"></i></a>
              <a href="tel:+6592714988"><i class="fas fa-phone-square fa-fw social-icons"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex d-none d-lg-block flex-row w-100">
        <ul
          class="nav nav-justified"
          style="margin-left: -12px; margin-right: -12px;"
        >
          <li class="nav-item" style="flex-basis: auto !important;">
            <a class="nav-link nav-custom text-uppercase" href="https://jhmetaphysics.com/services"
              >Services</a
            >
          </li>
          <li class="nav-item" style="flex-basis: auto !important;">
            <a
              class="nav-link nav-custom nav-custom-halfborder text-uppercase"
              href="https://jhmetaphysics.com/seminars"
             
              >Seminars & Courses</a
            >
          </li>
          <li class="nav-item" style="flex-basis: auto !important;">
            <a
              class="nav-link nav-custom nav-custom-halfborder text-uppercase"
              href="https://jhmetaphysics.com/resources"
              >Resources</a
            >
          </li>
          <li class="nav-item" style="flex-basis: auto !important;">
            <a
              class="nav-link nav-custom nav-custom-halfborder text-uppercase"
              href="https://jhmetaphysics.com/reviews"
              >Reviews</a
            >
          </li>
          <li class="nav-item" style="flex-basis: auto !important;">
            <a
              class="nav-link nav-custom nav-custom-halfborder text-uppercase"
              href="https://bazi.jhmetaphysics.com"
              >Bazi Chart</a
            >
          </li>
          <li class="nav-item" style="flex-basis: auto !important;">
            <a
              class="nav-link nav-custom nav-custom-halfborder text-uppercase"
              href="https://qmnatal.apps.jhmetaphysics.com"
              >Qimen Natal Chart</a
            >
          </li>
          <li class="nav-item" style="flex-basis: auto !important;">
            <a
              class="nav-link nav-custom nav-custom-halfborder text-uppercase"
              href="https://apps.jhmetaphysics.com"
              >JH Apps</a
            >
          </li>
        </ul>
      </div>
      <div class="d-sm-block d-md-block d-lg-none">
        <div
          class="navbar-collapse offcanvas-collapse"
          id="navbarsExampleDefault"
        >
          <ul
            class="nav flex-column"
            style="margin-left: -16px; margin-right: -16px"
          >
            <li class="nav-item">
              <a class="nav-link nav-custom-mobile text-uppercase" href="https://jhmetaphysics.com/services"
                >Services</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link nav-custom-mobile text-uppercase" href="https://jhmetaphysics.com/seminars"
                >Seminars & Courses</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link nav-custom-mobile text-uppercase" href="https://jhmetaphysics.com/resources"
                >Resources</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link nav-custom-mobile text-uppercase" href="https://jhmetaphysics.com/reviews"
                >Reviews</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link nav-custom-mobile text-uppercase" href="https://bazi.jhmetaphysics.com"
                >Bazi Chart</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link nav-custom-mobile text-uppercase" href="https://qmnatal.apps.jhmetaphysics.com"
                >Qimen Natal Chart</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link nav-custom-mobile text-uppercase" href="https://apps.jhmetaphysics.com"
                >JH Apps</a
              >
            </li>
            <li class="nav-item mt-4">
              <div
                class="d-flex flex-column align-items-center w-100"
                style="color: #939597; width: 150px; margin-right: 10px"
              >
                <div class="text-uppercase fw-bold">Stay Connected</div>
                <div style="margin-top: 5px">
                  <a href="#telegram" data-bs-toggle="modal"><i class="fab fa-telegram fa-fw fs-1 social-icons"></i></a>
                  <a href="https://wa.me/6592714988" target="_blank"><i class="fab fa-whatsapp-square fa-fw fs-1 social-icons"></i></a>
                  <a href="https://facebook.com/jhmasterpan" target="_blank"><i class="fab fa-facebook fa-fw fs-1 social-icons"></i></a>
                  <a href="tel:+6592714988"><i class="fas fa-phone-square fa-fw fs-1 social-icons"></i></a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <div class="modal" tabindex="-1" id="telegram">
      <div
        class="
          modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg
        "
      >
        <div class="modal-content">
          <div class="modal-header text-center">
            <h4 class="modal-title">
              Click or scan QR code to join us on
              <i class="fab fa-telegram"></i> Telegram
            </h4>
            <br />
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body text-center">
            <a href="https://t.me/jhmetaphysics" target="_blank"
              ><img
                src="https://chart.googleapis.com/chart?cht=qr&chl=https%3A%2F%2Ft.me%2Fjhmetaphysics&chs=180x180&choe=UTF-8&chld=L|2&chco=75787b"
                alt="qr code"
            /></a>
            <br /><a
              href="https://t.me/jhmetaphysics"
              target="_blank"
              class="link-secondary"
              >https://t.me/jhmetaphysics</a
            >
          </div>
        </div>
      </div>
    </div>
</template>

<script>
//import { Auth } from "aws-amplify";
export default {
  name: "TopNav",
  methods: {
  },
  mounted() {
    document.querySelector('[data-bs-toggle="offcanvas"]').addEventListener('click', function () {
    document.querySelector('.offcanvas-collapse').classList.toggle('open')
  })
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

body {
  padding-top: 16vh;
  background-color: #fff;
}
main {
  margin-top: 10px;
}
nav {
  padding-bottom: 0 !important;
}
.nav-logo {
  height: 10vh;
}
.nav-logo-cta {
  padding-left: var(--bs-gutter-x, 0.75rem);
  height: 10vh;
}
.social-icons {
  color: #939597;
}
.social-icons:hover {
  filter: invert(100%);
}
.nav-custom {
  background-color: #f5f5f5;
  color: #939597;
}
.nav-custom.active {
  background-color: #939597;
  color: #f5f5f5;
}
.nav-custom:hover {
  background-color: #939597;
  color: #f5f5f5;
}
.nav-custom-halfborder {
  position: relative;
}
.nav-custom-halfborder:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 25%;
  height: 50%;
  border-left: 1px #75787b solid;
}
.nav-custom-mobile {
  background-color: #f5f5f5;
  color: #939597;
}
.nav-custom-mobile:hover {
  color: #f5f5f5;
  background-color: #939597;
}
.nav-custom-mobile.active {
  color: #f5f5f5;
  background-color: #939597;
}
.yellow-line {
  background-color: #f5df4d;
  width: 150px;
  height: 2px;
}
.grey-line {
  background-color: #75787b;
  height: 0.5px;
}
.subheader {
  color: #75787b;
  margin-top: 5px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .social-icons {
    font-size: 25px;
  }
}
@media (max-width: 991.98px) {
  body {
    padding-top: calc(10vh + 16px);
  }
  .nav-logo {
    height: 10vh;
  }
  .nav-logo-cta {
    padding-left: 0;
    height: 10vh;
  }
  .offcanvas-collapse {
    position: fixed;
    top: calc(10vh + 16px); /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #f5f5f5;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }
  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(-100%);
  }
  .custom-mobile-text {
    font-size: 0.9rem;
  }
  .page-header {
    margin-top: -8px;
  }
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-underline .nav-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  color: #6c757d;
}

.nav-underline .nav-link:hover {
  color: #007bff;
}

.nav-underline .active {
  font-weight: 500;
  color: #343a40;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5);
}

.modal-title {
  color: #75787b;
}
h5.modal-title {
  color: #003a70;
}
.modal-title > small {
  color: #75787b !important;
}
.modal-body {
  color: #939597;
  font-size: 0.9rem;
}
.page-header {
  margin-top: -18px;
}
</style>
