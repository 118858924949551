import { createApp } from 'vue'
import App from './App.vue'
import VuePdf from 'vue3-pdfjs'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

const app = createApp(App);
app.use(VuePdf)
app.mount('#app')
