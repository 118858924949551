<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute -->
  <div>
    <div id="nav">
      <TopNav />
    </div>
    <Home />
    <footer class="footer mt-auto py-3 bg-light">
      <div class="container">
        <div class="row row-cols-1 row-cols-md-2" style="font-size: 0.8rem">
          <div class="col text-md-start text-center">
            <a href="#telegram" data-bs-toggle="modal" class="link-secondary"
              >Click to join our Telegram group for exclusive metaphysics
              information</a
            >
          </div>
          <div class="col text-md-end text-center mt-3 mt-md-0">
            <a
              href="https://wa.me/6592714988"
              target="_blank"
              class="link-secondary"
              style="text-decoration: none !important"
              >Contact us at +65 9271 4988</a
            >
          </div>
        </div>
        <div class="row d-none d-md-block">
          <div class="col">
            <div class="w-100 grey-line mt-3"></div>
          </div>
        </div>
        <div class="row text-center text-md-end" style="font-size: 0.8rem">
          <small class="text-muted mt-3"
            >Copyright © 2021 JH Metaphysics Consultancy Group. All rights
            reserved.</small
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
import TopNav from "@/components/TopNav.vue";
import Home from "@/components/Home.vue";

//import Amplify, { Auth, API } from "aws-amplify";
//import Amplify, { Auth } from "aws-amplify";
import Amplify from "aws-amplify";
Amplify.configure({
  API: {
    endpoints: [
      {
        name: "generate",
        endpoint: process.env.VUE_APP_QIMENNATALFREE_API,
      },
    ],
  },
});

export default {
  name: "App",
  created() {},
  data() {
    return {};
  },
  components: {
    TopNav,
    Home,
  },
  beforeUnmount() {},
};
</script>

<style>
html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

body {
  padding-top: 16vh;
  background-color: #fff;
}
@media (max-width: 991.98px) {
  body {
    padding-top: calc(10vh + 16px);
  }
}

.yellow-line {
  background-color: #f5df4d;
  width: 150px;
  height: 2px;
}
.grey-line {
  background-color: #75787b;
  height: 0.5px;
}
.subheader {
  color: #75787b;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
